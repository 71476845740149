import React from "react";
import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider, useMsal } from "@azure/msal-react";
import SSOButton from "./ssoButton";
import { toast } from "react-toastify";
import Cookies from 'js-cookie';

const MicrosoftLoginButton = ({Request}) => {
  const { instance } = useMsal();

  const handleMicrosoftLogin = () => {
    instance
      .loginPopup({ scopes: ["User.Read"] })
      .then( async (response) => {
        // console.log('CONNEXION REUSSIE');

        let res = await Request.Post('/website/microsoft/login', response.account);

        // console.log('res:', res)

        if (res.status === 401 || res.status === 400) {
          toast.error(res.message);
          return;
        } else if (res.status === 201) {
          const newWebsiteToken = res.token;
          // localStorage.setItem("websiteToken", newWebsiteToken);
          Cookies.set("websiteToken", newWebsiteToken, { expires: 7, secure: true });
          window.location.assign("/welcome");
        } else {
          const newWebsiteToken = res.token;
          // localStorage.setItem("websiteToken", newWebsiteToken);
          Cookies.set("websiteToken", newWebsiteToken, { expires: 7, secure: true });
          window.location.assign("/chat");
        }
      })
      .catch((err) => {
        toast.error("Erreur de connexion avec Microsoft:", err);
      });
  };

  return (
    <SSOButton
      imageUrl="https://img.icons8.com/color/48/000000/microsoft.png"
      text="Login with Microsoft"
      onClickFunction={handleMicrosoftLogin}
    />
  );
};

const MicrosoftLogin = ({ Request, clientId, redirectUri }) => {
  const msalConfig = {
    auth: {
      clientId,
      redirectUri,
    },
  };

  const msalInstance = new PublicClientApplication(msalConfig);

  return (
    <MsalProvider instance={msalInstance}>
      <MicrosoftLoginButton Request={Request}/>
    </MsalProvider>
  );
};

export default MicrosoftLogin;
