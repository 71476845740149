import { Paper } from "@mui/material";
import React, { useEffect, useState } from "react";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import CheckIcon from "@mui/icons-material/Check";
import PasswordIcon from "@mui/icons-material/Password";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import useRequest from "../../../../useRequest";
import { toast } from "react-toastify";

import Div100vh, { use100vh } from "react-div-100vh";

import NightsStayOutlinedIcon from "@mui/icons-material/NightsStayOutlined";
import Brightness4OutlinedIcon from "@mui/icons-material/Brightness4Outlined";

const Welcome = ({ navbarHeight, darkMode, toggleDarkMode }) => {
  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [checkPassword, setCheckPassword] = useState("");
  const [showCheckPassword, setShowCheckPassword] = useState(false);
  const [formValid, setFormValid] = useState(false);
  const [disabled, setDisabled] = useState(false);

  const Request = useRequest();

  let hundred = use100vh();

  const handleFirstNameChange = (event) => {
    setFirstname(event.target.value);
  };

  const handleLastNameChange = (event) => {
    setLastname(event.target.value);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const handleCheckPasswordChange = (event) => {
    setCheckPassword(event.target.value);
  };

  const handleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleShowCheckPassword = () => {
    setShowCheckPassword(!showCheckPassword);
  };

  useEffect(() => {
    setFormValid(
      firstname !== "" &&
        lastname !== "" &&
        password !== "" &&
        checkPassword !== "" &&
        password === checkPassword
    );
  }, [firstname, lastname, password, checkPassword]);


  const handleSubmit = async (event) => {
    if (!formValid) return;
    event.preventDefault();
    const obj = {
      firstname,
      lastname,
      password,
    };

    setDisabled(true);

    let res = await Request.Post("/protected/user/completeRegistration", obj);

    if (res.status === 401) {
      toast.error(res.message);
      setDisabled(false);
      return;
    } else {
      window.location.assign("/chat");
    }
  }

  return (
    <>
      <Div100vh
        className="section login"
        style={{
          height: hundred ? `calc(${hundred}px - ${navbarHeight}px)` : "100vh",
        }}
      >
        <div className="lines">
          <div className="line"></div>
          <div className="line"></div>
          <div className="line"></div>
        </div>
        <Paper elevation={2} className="cardLogin">
          <form onSubmit={handleSubmit}>
            <div className="form-group">
              <div className="form-head">
                <PersonOutlineIcon className="icon" />
                <p htmlFor="firstName">Prénom</p>
              </div>
              <input
                type="firstName"
                name="firstName"
                id="firstName"
                value={firstname}
                onChange={handleFirstNameChange}
                placeholder="Entrez votre prénom"
                required
              />
            </div>
            <div className="form-group">
              <div className="form-head">
                <PersonOutlineIcon className="icon" />
                <p htmlFor="lastname">Nom de famille</p>
              </div>
              <input
                type="lastname"
                name="lastname"
                id="lastname"
                value={lastname}
                onChange={handleLastNameChange}
                placeholder="Entrez votre nom de famille"
                required
              />
            </div>
            <div className="form-group">
              <div className="pass">
                <div className="form-head">
                  <PasswordIcon className="icon" />
                  <p htmlFor="password">mot de passe</p>
                </div>
                {showPassword ? (
                  <VisibilityOffIcon
                    className="icon clickable"
                    onClick={handleShowPassword}
                  />
                ) : (
                  <VisibilityIcon
                    className="icon clickable"
                    onClick={handleShowPassword}
                  />
                )}
              </div>
              <input
                type={showPassword ? "text" : "password"}
                name="password"
                id="password"
                placeholder="Entrez votre mot de passe"
                value={password}
                onChange={handlePasswordChange}
                required
              />
            </div>
            <div className="form-group">
              <div className="pass">
                <div className="form-head">
                  <CheckIcon className="icon" />
                  <p htmlFor="checkPassword">Confirmez mot de passe</p>
                </div>
                {showCheckPassword ? (
                  <VisibilityOffIcon
                    className="icon clickable"
                    onClick={handleShowCheckPassword}
                  />
                ) : (
                  <VisibilityIcon
                    className="icon clickable"
                    onClick={handleShowCheckPassword}
                  />
                )}
              </div>
              <input
                type={showCheckPassword ? "text" : "password"}
                name="checkPassword"
                id="checkPassword"
                placeholder="Confirmez votre mot de passe"
                value={checkPassword}
                onChange={handleCheckPasswordChange}
                required
              />
            </div>
            <button type="submit" disabled={!formValid || disabled}>Continuer</button>
          </form>
        </Paper>

        <div className="footer">
          <p>
            {" "}
            © All rights reserved -{" "}
            <a href="https://www.iadopt.fr" target="_blanc" rel="noreferrer">
              iadopt.
            </a>{" "}
            - {new Date().getFullYear()}
          </p>
        </div>
      </Div100vh>

      <div className="toggle-darkmode" onClick={toggleDarkMode}>
        {darkMode ? <Brightness4OutlinedIcon /> : <NightsStayOutlinedIcon />}
      </div>
    </>
  );
};

export default Welcome;
