import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";

const NewProvider = ({ Request, handleState, possibleProviders, company }) => {
  const [providerLabel, setProviderLabel] = useState("");
  const [provider, setProvider] = useState(null);
  const [connectionMethodValues, setConnectionMethodValues] = useState({});

  useEffect(() => {
    if (providerLabel) {
      const provider = possibleProviders.providers.find(
        (p) => p.Label === providerLabel
      );
      // console.log(provider);
      setProvider(provider);
      // Réinitialiser les valeurs de connexion et les erreurs lors du changement de fournisseur
      setConnectionMethodValues({});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [providerLabel]);

  useEffect(() => {
    if (provider) {
      // console.log("provider:", provider);
    }
  }, [provider]);

  const handleChange = (name, value) => {
    setConnectionMethodValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    // Exemple de validation (à étendre selon les besoins)
    if (
      Object.keys(connectionMethodValues).length <
      provider.ConnectionMethod.length
    ) {
      toast.error("Please fill in all required fields.");
      return;
    }
    // Envoyer les données ici
    // console.log("Submitting:", providerLabel, connectionMethodValues);

    try {
      let res = await Request.Post("/protected/company/addProvider", {
        companyId: company._id,
        providerLabel: providerLabel,
        connectionMethodValues,
      });
      // console.log("res:", res);
      toast.success("Provider added successfully.");
      handleState("back", null);
    } catch (error) {
      toast.error("An error occurred while adding the provider.");
    }
  };

  return (
    <div className="submodule">
      <div className="back" onClick={() => handleState("back", null)}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <line x1="19" y1="12" x2="5" y2="12"></line>
          <polyline points="12 19 5 12 12 5"></polyline>
        </svg>
      </div>
      <div className="content">
        <h2>Add a new AI Provider</h2>
        <form onSubmit={handleSubmit}>
          <select
            onChange={(event) => setProviderLabel(event.target.value)}
            value={providerLabel}
          >
            <option value="">Select a provider</option>
            {possibleProviders.providers.map((provider, index) => (
              <option key={index} value={provider.Label}>
                <img src={provider.LogoUrl} alt={provider.Label} />
                {provider.Label}
              </option>
            ))}
          </select>
          {provider &&
            provider.ConnectionMethod.map((method, index) => (
              <div className="input-provider">
                <h4>
                  {method.name}
                  <span>{method.required ? " *" : ""}</span>
                </h4>
                <input
                  key={index}
                  type="text"
                  placeholder={`${method.example}`}
                  required={method.required}
                  onChange={(e) => handleChange(method.name, e.target.value)}
                />
              </div>
            ))}
          <button type="submit">Add Provider</button>
        </form>
      </div>
    </div>
  );
};

export default NewProvider;
