const FileUpload = ({ param, isValid, isUploading, onFileChange, inputFileRef, uploadProgress, multiple }) => (
    <div>
      <div
        className={`param-button ${isValid ? "valid" : isUploading ? "upload" : "active"}`}
        onClick={() => inputFileRef.current.click()}
      >
        {!isUploading ? (
          isValid ? (
            <div className="pdf-function">
              <div className="logo">
                <img
                  src={param.type === "pdf" ? "https://ik.imagekit.io/z0tzxea0wgx/Cogitum/PDF_file_icon.svg_hFHGoPbIC.png?updatedAt=1686166532222&tr=h-100,w-auto" : "https://ik.imagekit.io/z0tzxea0wgx/Cogitum/zip_aHvCGeB1f.webp?updatedAt=1692205927836&tr=h-100,w-auto"}
                  alt={`${param.type}-logo`}
                />
              </div>
              <div className="content">
                {param.fileProps.map((file, index) => (
                  <div key={index}>
                    <div className="title">{file.fileName}</div>
                    <div className="pages">{file.fileSize}</div>
                  </div>
                ))}
              </div>
            </div>
          ) : (
            <img
              src={param.type === "pdf" ? "https://ik.imagekit.io/z0tzxea0wgx/Cogitum/addpdf_Wa31ZcO5L.png?updatedAt=1689086030740" : "https://ik.imagekit.io/z0tzxea0wgx/Cogitum/addzip_B0a8Hw0C.png?updatedAt=1689086030740"}
              alt={`add${param.type}`}
            />
          )
        ) : (
          <>
            <div className="overlay"></div>
            <div className="spinner">
              <div className="spinner-circle"></div>
              <div className="spinner-text">{`${parseInt(uploadProgress)}%`}</div>
            </div>
          </>
        )}
      </div>
      <input
        ref={inputFileRef}
        type="file"
        accept=".pdf,.docx,.zip"
        onChange={onFileChange}
        style={{ display: "none" }}
        disabled={isUploading}
        multiple={multiple}
      />
    </div>
);

export default FileUpload;
