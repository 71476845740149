import { useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import useRequest from "../../useRequest";

import NightsStayOutlinedIcon from "@mui/icons-material/NightsStayOutlined";
import Brightness4OutlinedIcon from "@mui/icons-material/Brightness4Outlined";
import ChatFormatted from "../dashboard/components/chat/chatFormatted";
import PDFViewer from "../dashboard/CogiProducts/PDFViewer.jsx";

import Div100vh from "react-div-100vh";

const SharedChat = ({ darkMode, toggleDarkMode }) => {
  const { id } = useParams();
  const [messages, setMessages] = useState(null);

  const [showPdfViewer, setShowPdfViewer] = useState(false);
  const [pdfFile, setPdfFile] = useState("");
  const [pdfPage, setPdfPage] = useState(1);
  const [highlightText, setHighlightText] = useState("");

  const Request = useRequest();

  useEffect(() => {
    const fetchData = async () => {
      let res = await Request.Get(`public/shared/${id}`);
      setMessages(res.messages);
    };
    fetchData();
    //eslint-disable-next-line
  }, []);

  return (
    <Div100vh className="shared">
      <Div100vh className="dashboard-container">
        <div className="cogichat">
          <div className="big-container">
            <div className="content chat">
              <div className="chat-messages">
                {messages &&
                  messages.length > 0 &&
                  messages.map((message, index) => (
                    <div
                      key={index}
                      className={`message ${
                        index % 2 === 0 ? "pair" : "unpair"
                      }`}
                    >
                      <ChatFormatted
                        Request={Request}
                        key={index}
                        message={message}
                        darkMode={darkMode}
                        editable={false}
                        shared={true}
                        setShowPdfViewer={setShowPdfViewer}
                        setPdfFile={setPdfFile}
                        setPdfPage={setPdfPage}
                        setHighlightText={setHighlightText}
                      />
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </div>
        <div className="toggle-darkmode" onClick={toggleDarkMode}>
          {darkMode ? <Brightness4OutlinedIcon /> : <NightsStayOutlinedIcon />}
        </div>
      </Div100vh>
      {showPdfViewer && (
          <Div100vh className="pdf-viewer-container">
            <PDFViewer
              fileUrl={pdfFile}
              pageNumber={pdfPage}
              highlightText={highlightText}
              setShowPdfViewer={setShowPdfViewer}
              darkMode={darkMode}
            />
          </Div100vh>
        )}
    </Div100vh>
  );
};

export default SharedChat;
