import React, { useState, useRef, useCallback, useEffect } from "react";
import ConfirmationModal from "./confirmationModal";
import Cookies from "js-cookie";
import Checklist from "./CheckList";
import PlurialTextInput from "./PlurialTextInput";
import FileUpload from "./fileUpload";
import { toast } from "react-toastify";

const Parameters = ({
  param,
  index,
  setParameters,
  setParamStatuses,
  parameters,
  Request,
  template,
  mode,
}) => {
  const [selectedItems, setSelectedItems] = useState([]);
  const [isValid, setIsValid] = useState(false);
  const [fileProps, setFileProps] = useState([]);
  const [isUploading, setIsUploading] = useState(false);
  const [checklistActiveButton, setChecklistActiveButton] = useState(null);
  const [textInputs, setTextInputs] = useState([]);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isToggleModalOpen, setIsToggleModalOpen] = useState(false);
  const [currentToggleIdx, setCurrentToggleIdx] = useState(null);
  const [toggleDirection, setToggleDirection] = useState(false);
  const [initialToggleState, setInitialToggleState] = useState(null);
  const [deleteIdx, setDeleteIdx] = useState(null);
  const inputFileRef = useRef(null);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [display, setDisplay] = useState(true);

  useEffect(() => {
    if (param.value && param.value.length > 0) {
      const selectedMode = mode;
      // console.log("mode:", mode);
      let modeValue = param.value.find((v) => v.mode === selectedMode);

      if (mode === "preprod" || mode === "studio") {
        setDisplay(true);
      } else {
        setDisplay(false);
      }

      if (modeValue) {
        setTextInputs(modeValue.value);
      }
    }

    if (!param.mandatory) {
      setParamStatuses((prevStatuses) => {
        const newStatuses = [...prevStatuses];
        newStatuses[index] = true;
        return newStatuses;
      });
    }
  }, [param, mode, setParamStatuses, index]);

  useEffect(() => {
    if (
      param.type.includes("checklist") ||
      param.type.includes("plurial-text-inputs")
    ) {
      if (parameters[index]?.content) {
        setParamStatuses((prevStatuses) => {
          const newStatuses = [...prevStatuses];
          newStatuses[index] = true;
          return newStatuses;
        });
      } else {
        setParamStatuses((prevStatuses) => {
          const newStatuses = [...prevStatuses];
          newStatuses[index] = false;
          return newStatuses;
        });
      }
    }
    //eslint-disable-next-line
  }, [parameters]);

  useEffect(() => {
    setParameters((prevParameters) => {
      const newParameters = [...prevParameters];
      newParameters[index] = {
        label: param.label,
        type: param.type,
        content: selectedItems,
      };
      return newParameters;
    });
    //eslint-disable-next-line
  }, [selectedItems]);

  useEffect(() => {
    setParameters((prevParameters) => {
      const newParameters = [...prevParameters];
      newParameters[index] = {
        label: param.label,
        type: param.type,
        content: textInputs,
      };
      return newParameters;
    });
    //eslint-disable-next-line
  }, [textInputs]);

  const uploadFile = async (file) => {
    const formData = new FormData();
    formData.append("fileField", file);

    setIsUploading(true);

    const response = await Request.PostFile(
      "/protected/uploads",
      formData,
      file.name,
      param.minioTags,
      (progress) => setUploadProgress(progress),
      Cookies.get("websiteToken")
    );

    setIsUploading(false);
    setUploadProgress(0);

    if (response.message === "File processed") {
      setParamStatuses((prevStatuses) => {
        const newStatuses = [...prevStatuses];
        newStatuses[index] = true;
        return newStatuses;
      });

      let fileName = response.fileName;

      setParameters((prevParameters) => {
        const newParameters = [...prevParameters];

        // On s'assure d'utiliser toujours la version la plus récente de param.content
        const currentParam = newParameters[index];
        const updatedContent =
          currentParam.content && Array.isArray(currentParam.content)
            ? [...currentParam.content, fileName]
            : [fileName];

        newParameters[index] = {
          ...currentParam, // On conserve les autres propriétés existantes
          content: updatedContent, // On met à jour le contenu
        };

        return newParameters; // On retourne les paramètres mis à jour
      });
    }

    return response;
  };

  const onFileChange = useCallback(async (event) => {
    try {
      const files = Array.from(event.target.files);

      let validFiles = [];

      let allowedTypes;

      if (param.type.includes("pdf")) {
        allowedTypes = [
          "application/pdf",
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        ];

        for (let i = 0; i < files.length; i++) {
          const fileType = files[i].type;
          if (fileType !== "" && allowedTypes.includes(fileType)) {
            validFiles.push(files[i]); // fichier valide
          } else {
            toast.error(`${files[i].name} => Pas le bon type de fichier.`);
          }
        }

        if (validFiles.length === 0) {
          return;
        }
      }

      if (param.type === "multiple-pdf") {
        const responses = await Promise.all(
          validFiles.map((file) => uploadFile(file))
        );

        // Mettez à jour fileProps avec les nouveaux fichiers
        const newFiles = responses.map((data, index) => ({
          fileName: data.fileName,
          fileSize: data.size,
        }));
        setFileProps((prev) => [...prev, ...newFiles]);
        setIsValid(true);
      } else {
        const data = await uploadFile(event.target.files[0]);
        setFileProps([{ fileName: data.fileName, fileSize: data.size }]);
        setIsValid(true);
      }
    } catch (error) {
      console.error("Error uploading file:", error);
    }
    // eslint-disable-next-line
  }, []);

  const checklistButtonClick = useCallback((item, idx) => {
    setChecklistActiveButton(idx);
    setIsValid(true);
    setParameters((prevParameters) => {
      const newParameters = [...prevParameters];
      newParameters[index] = {
        label: param.label,
        type: param.type,
        content: item,
      };
      return newParameters;
    });
    setParamStatuses((prevStatuses) => {
      const newStatuses = [...prevStatuses];
      newStatuses[index] = true;
      return newStatuses;
    });
    //eslint-disable-next-line
  }, []);

  const toggleItem = (item, index, idx) => {
    if (selectedItems.includes(item)) {
      setSelectedItems(selectedItems.filter((i) => i !== item));
    } else {
      setSelectedItems([...selectedItems, item]);
    }
  };

  const toggleAllItems = (fields) => {
    const allSelected = fields.every((field) => selectedItems.includes(field));

    if (allSelected) {
      setSelectedItems(selectedItems.filter((item) => !fields.includes(item)));
    } else {
      setSelectedItems([...new Set([...selectedItems, ...fields])]);
    }
  };

  const updateReferential = async (projectId, index, obj) => {
    const requestData = {
      projectId,
      index,
      obj,
    };

    try {
      const endpoint = "/protected/project/updateValue";
      const response = await Request.Post(endpoint, requestData);
      // console.log("Backend response:", response);
    } catch (error) {
      console.error("Error making backend request:", error);
    }
  };

  const handleTextChange = (index, event) => {
    const newText = event.target.value;

    setParameters((prevParameters) => {
      const newParameters = [...prevParameters];
      newParameters[index] = {
        label: param.label,
        type: "string",
        content: newText,
      };
      return newParameters;
    });

    setParamStatuses((prevStatuses) => {
      const newStatuses = [...prevStatuses];
      newStatuses[index] = newText !== "";
      return newStatuses;
    });
  };

  const handlePlurialTextChange = (idx, key, event) => {
    const newTextInputs = [...textInputs];

    if (event.target) {
      newTextInputs[idx][key] = event.target.value;
    } else {
      newTextInputs[idx][key] = event;
    }

    setTextInputs(newTextInputs);

    setParameters((prevParameters) => {
      const newParameters = [...prevParameters];
      newParameters[index] = {
        type: "plurial-text-inputs",
        content: newTextInputs,
      };
      return newParameters;
    });

    setParamStatuses((prevStatuses) => {
      const newStatuses = [...prevStatuses];
      newStatuses[index] = newTextInputs.some(
        (input) => input.requirementName !== "" && input.prompt !== ""
      );
      return newStatuses;
    });
  };

  const addTextInput = () => {
    setTextInputs([
      ...textInputs,
      { requirementName: "", prompt: "", default: false, disabled: false },
    ]);
  };

  const handleDuplicate = (idx) => {
    setTextInputs((prevTextInputs) => {
      const newTextInputs = [
        ...prevTextInputs.slice(0, idx + 1),
        { ...prevTextInputs[idx], default: false, disabled: false },
        ...prevTextInputs.slice(idx + 1),
      ];

      // Mise à jour des paramètres
      setParameters((prevParameters) => {
        const newParameters = [...prevParameters];
        newParameters[index] = {
          type: "plurial-text-inputs",
          content: newTextInputs,
        };
        return newParameters;
      });

      return newTextInputs;
    });
  };

  const handleToggleSwitch = (idx, textInput) => {
    setCurrentToggleIdx(idx);
    setToggleDirection(!textInput.default);
    setInitialToggleState(textInput.default);
    setIsToggleModalOpen(true);
  };

  const confirmToggle = async () => {
    if (currentToggleIdx !== null) {
      // Mettez à jour l'élément 'default' dans 'textInputs' pour l'élément sélectionné
      const newTextInputs = [...textInputs];
      newTextInputs[currentToggleIdx].default = toggleDirection;
      setTextInputs(newTextInputs);

      // Mettez à jour les paramètres avec les nouvelles valeurs de 'textInputs'
      setParameters((prevParameters) => {
        const newParameters = [...prevParameters];
        newParameters[index] = {
          type: "plurial-text-inputs",
          content: newTextInputs,
        };
        return newParameters;
      });

      // Récupérer l'ID du projet
      const projectId = template.id
        ? template.id
        : template._id
        ? template._id
        : null;

      // Mise à jour correcte de 'param.value' pour le mode courant (dev ou prod)
      const modeIndex = param.value.findIndex((v) => v.mode === mode);

      if (modeIndex !== -1) {
        param.value[modeIndex].value = newTextInputs;
      } else {
        console.error("Mode non trouvé dans param.value");
      }

      // console.log(param.value);

      await updateReferential(projectId, index, param.value);

      setCurrentToggleIdx(null);
      setIsToggleModalOpen(false);
    }
  };

  const cancelToggle = () => {
    if (currentToggleIdx !== null) {
      const newTextInputs = [...textInputs];
      newTextInputs[currentToggleIdx].default = initialToggleState;
      setTextInputs(newTextInputs);
    }

    setCurrentToggleIdx(null);
    setIsToggleModalOpen(false);
  };

  const openDeleteModal = (idx) => {
    setDeleteIdx(idx);
    setIsDeleteModalOpen(true);
  };

  const closeDeleteModal = () => {
    setDeleteIdx(null);
    setIsDeleteModalOpen(false);
  };

  const handleDelete = async () => {
    if (deleteIdx !== null) {
      const newTextInputs = textInputs.filter((_, idx) => idx !== deleteIdx);
      setTextInputs(newTextInputs);

      setParameters((prevParameters) => {
        const newParameters = [...prevParameters];
        newParameters[index] = {
          type: "plurial-text-inputs",
          content: newTextInputs,
        };
        return newParameters;
      });

      // Récupérer l'ID du projet
      const projectId = template.id
        ? template.id
        : template._id
        ? template._id
        : null;

      // Mise à jour correcte de 'param.value' pour le mode courant (dev ou prod)
      const modeIndex = param.value.findIndex((v) => v.mode === mode);
      if (modeIndex !== -1) {
        param.value[modeIndex].value = newTextInputs;
      } else {
        console.error("Mode non trouvé dans param.value");
      }

      // console.log(param.value);

      await updateReferential(projectId, index, param.value);

      setDeleteIdx(null);
      setIsDeleteModalOpen(false);
    }
  };

  const handleMoveUp = (idx) => {
    if (idx > 0) {
      setTextInputs((prevTextInputs) => {
        const newTextInputs = [...prevTextInputs];
        [newTextInputs[idx - 1], newTextInputs[idx]] = [
          newTextInputs[idx],
          newTextInputs[idx - 1],
        ];

        // Mise à jour des paramètres
        setParameters((prevParameters) => {
          const newParameters = [...prevParameters];
          newParameters[index] = {
            type: "plurial-text-inputs",
            content: newTextInputs,
          };
          return newParameters;
        });

        return newTextInputs;
      });
    }
  };

  const handleMoveDown = (idx) => {
    if (idx < textInputs.length - 1) {
      setTextInputs((prevTextInputs) => {
        const newTextInputs = [...prevTextInputs];
        [newTextInputs[idx], newTextInputs[idx + 1]] = [
          newTextInputs[idx + 1],
          newTextInputs[idx],
        ];

        // Mise à jour des paramètres
        setParameters((prevParameters) => {
          const newParameters = [...prevParameters];
          newParameters[index] = {
            type: "plurial-text-inputs",
            content: newTextInputs,
          };
          return newParameters;
        });

        return newTextInputs;
      });
    }
  };

  if (!display) {
    return null;
  }

  return (
    <div
      className={`param-container ${
        param.type.includes("checklist") ? "double no-click" : "single click"
      }`}
    >
      <h3>{param.label}</h3>
      {param.type.includes("checklist") ? (
        <Checklist
          param={param}
          checklistActiveButton={checklistActiveButton}
          checklistButtonClick={checklistButtonClick}
          selectedItems={selectedItems}
          toggleItem={toggleItem}
          toggleAllItems={toggleAllItems}
        />
      ) : param.type === "zip" ||
        param.type === "pdf" ||
        param.type === "multiple-pdf" ? (
        <FileUpload
          param={{ ...param, fileProps }}
          isValid={isValid}
          isUploading={isUploading}
          onFileChange={onFileChange}
          inputFileRef={inputFileRef}
          uploadProgress={uploadProgress}
          multiple={param.type === "multiple-pdf"}
        />
      ) : param.type === "string" ? (
        <div className="textbox">
          <input
            type="text"
            value={param.content}
            disabled={false}
            placeholder={param.value}
            onChange={(event) => handleTextChange(index, event)}
          />
        </div>
      ) : param.type === "plurial-text-inputs" ? (
        <>
          <div className="plurial-text-inputs">
            {textInputs.map((textInput, idx) => (
              <PlurialTextInput
                key={idx}
                textInput={textInput}
                idx={idx}
                handlePlurialTextChange={handlePlurialTextChange}
                handleDuplicate={handleDuplicate}
                handleToggleSwitch={handleToggleSwitch}
                openDeleteModal={openDeleteModal}
                handleMoveUp={handleMoveUp}
                handleMoveDown={handleMoveDown}
                Request={Request}
              />
            ))}
            <ConfirmationModal
              open={isDeleteModalOpen}
              title="Êtes-vous sûr de vouloir supprimer la norme ?"
              text=""
              onConfirm={handleDelete}
              onCancel={closeDeleteModal}
            />
            <ConfirmationModal
              open={isToggleModalOpen}
              title={toggleDirection ? "Enregister" : "Modifier"}
              text={
                toggleDirection
                  ? "En enregistrant cette règle, elle sera éxecutée par défaut et ne sera pas modifiable."
                  : "En désactivant cette règle, elle ne sera plus éxecutée par défaut et sera modifiable."
              }
              onConfirm={confirmToggle}
              onCancel={cancelToggle}
            />
          </div>
          <button className="addTextInput" onClick={addTextInput} disabled={mode === "studio" && textInputs.length >= 5}>
            +
          </button>
        </>
      ) : null}
    </div>
  );
};

export default Parameters;
