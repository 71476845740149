import Cookies from 'js-cookie';

const useRequest = () => {
  const Request = require("./request").default;

  const token = Cookies.get('websiteToken');

  const getRequestFunction = (originalFunction) => {
    return async (...args) => {
      return originalFunction(...args, token);
    };
  };

  const RequestObj = {
    Get: getRequestFunction(Request.Get),
    Post: getRequestFunction(Request.Post),
    PostFile: Request.PostFile,
    Put: getRequestFunction(Request.Put),
    Delete: getRequestFunction(Request.Delete),
  };

  return RequestObj;
};

export default useRequest;

