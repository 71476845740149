import { Paper } from "@mui/material";
import React, { useEffect, useState } from "react";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import useRequest from "../../../../useRequest";
import { toast } from "react-toastify";

import Div100vh, { use100vh } from "react-div-100vh";

import NightsStayOutlinedIcon from "@mui/icons-material/NightsStayOutlined";
import Brightness4OutlinedIcon from "@mui/icons-material/Brightness4Outlined";

const Password = ({ navbarHeight, darkMode, toggleDarkMode }) => {
  const [email, setEmail] = useState("");
  const [formValid, setFormValid] = useState(false);
  const [disabled, setDisabled] = useState(false);

  const Request = useRequest();

  let hundred = use100vh();

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  useEffect(() => {
    setFormValid(email !== "");
  }, [email]);

  const handleSubmit = async (event) => {
    if (!formValid) return;
    event.preventDefault();
    const obj = {
      email,
    };

    setDisabled(true);

    let res = await Request.Post("/website/user/passwordrecovery", obj);

    if (res.status === 401 || res.status === 400) {
      toast.error(res.message);
      setDisabled(false);
      return;
    } else {
      toast.success(res.message);
      setTimeout(() => {
        window.location.assign("/");
      }, 7000);
    }
  };

  return (
    <>
      <Div100vh
        className="section login"
        style={{
          height: hundred ? `calc(${hundred}px - ${navbarHeight}px)` : "100vh",
        }}
      >
        <div className="lines">
          <div className="line"></div>
          <div className="line"></div>
          <div className="line"></div>
        </div>
        <Paper elevation={2} className="cardLogin password-recovery">
          <h2>Password recovery</h2>
          <p className="password-text">
            Please enter your email address to receive a temporary password for
            your account. This will allow you to access your account securely.
            Thank you!
          </p>
          <form onSubmit={handleSubmit}>
            <div className="form-group">
              <div className="form-head">
                <MailOutlineIcon className="icon" />
                <p htmlFor="firstName">Email</p>
              </div>
              <input
                type="email"
                name="email"
                id="email"
                value={email}
                onChange={handleEmailChange}
                placeholder="Enter your email address"
                required
              />
            </div>
            <button type="submit" disabled={!formValid || disabled}>
              Continue
            </button>
          </form>
        </Paper>

        <div className="footer">
          <p>
            {" "}
            © All rights reserved -{" "}
            <a href="https://www.iadopt.fr" target="_blanc" rel="noreferrer">
              iadopt.
            </a>{" "}
            - {new Date().getFullYear()}
          </p>
        </div>
      </Div100vh>

      <div className="toggle-darkmode" onClick={toggleDarkMode}>
        {darkMode ? <Brightness4OutlinedIcon /> : <NightsStayOutlinedIcon />}
      </div>
    </>
  );
};

export default Password;
