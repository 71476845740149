import { DuplicateIcon, DeleteIcon, UpIcon, DownIcon, FileIcon } from "./icons"; // Assurez-vous que FileIcon est importé
import Switch from "@mui/material/Switch";
import { useState, useEffect } from "react";

const PlurialTextInput = ({
  textInput,
  idx,
  handlePlurialTextChange,
  handleDuplicate,
  handleToggleSwitch,
  openDeleteModal,
  handleMoveUp,
  handleMoveDown,
}) => {
  const [showFileKeywords, setShowFileKeywords] = useState(
    (textInput.type && textInput.type === "Comparaison") || textInput.tags
  );
  const [fileKeywords, setFileKeywords] = useState(textInput.tags ? textInput.tags : []);
  const [currentKeyword, setCurrentKeyword] = useState("");
  const [selectedType, setSelectedType] = useState(textInput.type ? textInput.type : 'Classique');

  useEffect(() => {
    // Vérifier si selectedType est différent de "Comparaison"
    if (selectedType !== "Comparaison") {
      setShowFileKeywords(false);
      setFileKeywords([]);
      handlePlurialTextChange(idx, "tags", []); // Mettez à jour l'état parent
    } else {
      setShowFileKeywords(true);
    }
  }, [selectedType]); // Dépendance sur selectedType

  const addKeyword = () => {
    if (textInput.default) {
      return; // Impossible d'ajouter un mot-clé si par défaut
    }

    const trimmedKeyword = currentKeyword.trim();
    if (trimmedKeyword && !fileKeywords.includes(trimmedKeyword)) {
      const updatedKeywords = [...fileKeywords, trimmedKeyword];
      setFileKeywords(updatedKeywords);
      handlePlurialTextChange(idx, "tags", updatedKeywords); // Déclenchement de la fonction
      setCurrentKeyword(""); // Réinitialiser le champ
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault(); // Empêche le comportement par défaut de l'input
      addKeyword();
    }
  };

  const removeKeyword = (index) => {
    if (textInput.default) {
      return; // Impossible de supprimer un mot-clé si par défaut
    }

    const newKeywords = fileKeywords.filter((_, i) => i !== index);
    setFileKeywords(newKeywords);
    handlePlurialTextChange(idx, "tags", newKeywords); // Déclenchement de la fonction
  };

  const handleTypeChange = (event) => {
    setSelectedType(event.target.value);
    textInput.type = event.target.value;
  };

  return (
    <div className="plurial-text-inputs-component">
      <div className="inputs">
        <div className="input-actions">
          <input
            type="text"
            value={textInput.requirementName}
            placeholder="Nom de l'exigence"
            onChange={(event) =>
              handlePlurialTextChange(idx, "requirementName", event)
            }
            disabled={textInput.default}
          />
          <div className="action-buttons">
            <div
              className="action"
              title="Monter norme"
              onClick={() => handleMoveUp(idx)}
            >
              <UpIcon />
            </div>
            <div
              className="action"
              title="Dupliquer norme"
              onClick={() => handleMoveDown(idx)}
            >
              <DownIcon />
            </div>
            <div
              className="action duplicate"
              title="Dupliquer norme"
              onClick={() => handleDuplicate(idx)}
            >
              <DuplicateIcon />
            </div>
            <div
              className="action delete"
              title="Supprimer norme"
              onClick={() => openDeleteModal(idx)}
            >
              <DeleteIcon />
            </div>
            <div className="dropdown" title="Choisir une option">
              <select
                value={selectedType}
                onChange={(e) => handleTypeChange(e)}
                className="dropdown-select"
                disabled={textInput.default}
              >
                <option value="Classique">Classique</option>
                <option value="Comparaison">Comparaison</option>
                <option value="Condition">Condition</option>
              </select>
            </div>
            <div title="Enregistrer la norme">
              <Switch
                checked={textInput.default}
                className="switch"
                onChange={() => handleToggleSwitch(idx, textInput)}
              />
            </div>
          </div>
        </div>
        <textarea
          type="text"
          value={textInput.prompt}
          placeholder="Prompt"
          onChange={(event) => handlePlurialTextChange(idx, "prompt", event)}
          disabled={textInput.default}
        />
        {showFileKeywords && (
          <div className="file-keywords">
            {!textInput.default && <h3>Ajoute des mots-clés de fichiers</h3>}
            {fileKeywords.length > 0 && (
              <div className="keyword-list">
                {fileKeywords.map((keyword, index) => (
                  <div key={index} className="keyword-display">
                    <span>{keyword}</span>
                    <span
                      className="remove-icon"
                      onClick={() => removeKeyword(index)}
                      style={{ cursor: "pointer", marginLeft: "5px" }}
                    >
                      {!textInput.default && "✖"}
                    </span>
                  </div>
                ))}
              </div>
            )}
            {!textInput.default && (
              <div className="keyword-input">
                <input
                  type="text"
                  value={currentKeyword}
                  placeholder="Mot-clé de fichier"
                  onChange={(event) => setCurrentKeyword(event.target.value)}
                  onKeyDown={handleKeyDown} // Ajout de l'événement onKeyDown
                />
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default PlurialTextInput;
