import React from "react";

const FileContainer = ({ index, type, fileName, fileSize, removeFile }) => {
  return (
    <div className="file-container">
      <div className="left">
        {type === "pdf" ? (
          <img
            src="https://ik.imagekit.io/z0tzxea0wgx/Cogitum/PDF_file_icon.svg_hFHGoPbIC.png?updatedAt=1686166532222&tr=h-100,w-auto"
            alt="pdf-logo"
          />
        ) : type === "docx" ? (
          <img
            src="https://ik.imagekit.io/z0tzxea0wgx/Iadopt/docx_icon.svg_FM5hi3NmK.png?updatedAt=1712678598218"
            alt="pdf-logo"
          />
        ) : null}
      </div>
      <div className="right">
        <p className="file-name" title={fileName}>{fileName}</p>
        <p className="file-size">{fileSize}</p>
      </div>
      <div className="cross" onClick={() => removeFile(index)}>
        <svg
          stroke="currentColor"
          fill="none"
          strokeWidth="2"
          viewBox="0 0 24 24"
          strokeLinecap="round"
          strokeLinejoin="round"
          className="text-gray-900 dark:text-gray-200"
          height="20"
          width="20"
          xmlns="http://www.w3.org/2000/svg"
        >
          <line x1="18" y1="6" x2="6" y2="18"></line>
          <line x1="6" y1="6" x2="18" y2="18"></line>
        </svg>
      </div>
    </div>
  );
};

export default FileContainer;
