import { useGoogleLogin } from "react-google-login";
import SSOButton from "./ssoButton";
import { toast } from "react-toastify";
import Cookies from 'js-cookie';

const GoogleLoginButton = ({ Request, clientId }) => {

    const onSuccess = async (response) => {
        let res = await Request.Post('/website/google/login', response.profileObj);

        if (res.status === 401 || res.status === 400) {
          toast.error(res.message);
          return;
        } else if (res.status === 201) {
          const newWebsiteToken = res.token;
          Cookies.set("websiteToken", newWebsiteToken, { expires: 7, secure: true });
          // localStorage.setItem("websiteToken", newWebsiteToken);
          window.location.assign("/welcome");
        } else {
          const newWebsiteToken = res.token;
          Cookies.set("websiteToken", newWebsiteToken, { expires: 7, secure: true });
          // localStorage.setItem("websiteToken", newWebsiteToken);
          window.location.assign("/chat");
        }
    };

    const onFailure = (response) => {
        // console.log("LOGIN Failed ! res : ", response.profileObj);
    };

    const { signIn } = useGoogleLogin({
        onSuccess,
        onFailure,
        cookiePolicy: 'single_host_origin',
        clientId : clientId,
        isSignedIn: false,
    })

    return (
        <SSOButton
            imageUrl="https://img.icons8.com/color/48/000000/google-logo.png"
            text="Login with Google"
            onClickFunction={signIn}
      />
    );

};

export default GoogleLoginButton;