import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import { ToastContainer } from "react-toastify";
import Loading from "./components/website/root/loading/loading";
import Login from "./components/website/form/login/login";
import Welcome from "./components/website/form/welcome/welcome";
import Register from "./components/website/form/register/register";
import Password from "./components/website/form/password/password";
import CogiPro from "./components/dashboard/CogiProducts/cogipro.jsx";
import CogiWelcome from "./components/dashboard/CogiProducts/cogiwelcome.jsx";
import useRequest from "./useRequest";
import Dashboard from "./components/dashboard/dashboard";
import SharedChat from "./components/shared/sharedChat";
import FileUpload from "./components/dev/fileUpload/fileUpload.jsx";
import Cookies from "js-cookie";

import jwt_decode from "jwt-decode";

function Wrapper({ darkMode, toggleDarkMode, loading }) {
  return (
    <div className={`App ${darkMode ? "dark-mode" : "light-mode"}`}>
      {loading ? <Loading darkMode={darkMode} /> : null}
      <Routes>
        <Route
          path="/"
          element={
            <Login darkMode={darkMode} toggleDarkMode={toggleDarkMode} />
          }
        />
        <Route
          path="/password-recovery"
          element={
            <Password darkMode={darkMode} toggleDarkMode={toggleDarkMode} />
          }
        />
        <Route
          path="/welcome"
          element={
            <Welcome darkMode={darkMode} toggleDarkMode={toggleDarkMode} />
          }
        />
        <Route
          path="/register"
          element={
            <Register darkMode={darkMode} toggleDarkMode={toggleDarkMode} />
          }
        />
        <Route
          path="/dashboard"
          element={
            <Dashboard darkMode={darkMode} toggleDarkMode={toggleDarkMode} />
          }
        />
        <Route
          path="/chat"
          element={
            <CogiPro darkMode={darkMode} toggleDarkMode={toggleDarkMode} />
          }
        />
        <Route
          path="/onboarding"
          element={
            <CogiWelcome darkMode={darkMode} toggleDarkMode={toggleDarkMode} />
          }
        />
        <Route
          path="/shared/:id"
          element={
            <SharedChat darkMode={darkMode} toggleDarkMode={toggleDarkMode} />
          }
        />
        <Route path="/fileupload" element={<FileUpload />} />
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
    </div>
  );
}

function App() {
  const [darkMode, setDarkMode] = useState(false);
  const [loading] = useState(true);
  const [isTokenSet, setIsTokenSet] = useState(false);

  const Request = useRequest();

  const getApiUrl = async () => {
    try {
      const tokenres = await fetch("https://license.iadopt.fr/public/token", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (!tokenres.ok) {
        throw new Error("Failed to fetch token");
      }

      const token = await tokenres.json();

      const apiUrlres = await fetch("https://license.iadopt.fr/protected/company/getapiurl", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token.licenseApiToken}`,
        },
        body: JSON.stringify({ frontUrl: window.location.origin }),
      });

      if (!apiUrlres.ok) {
        throw new Error("Failed to fetch API URL");
      }

      const apiUrl = await apiUrlres.json();
      Cookies.set("apiUrl", apiUrl.apiUrl, { expires: 7, secure: true, path: '/' });

      window.location.reload();
    } catch (error) {
      console.error(error);
    }
  };

  const isPublicRoute = () => {
    return (
      window.location.pathname === "/register" ||
      window.location.pathname === "/password-recovery" ||
      window.location.pathname.includes("shared")
    );
  };

  const handleToken = async () => {
    const storedToken = Cookies.get("websiteToken");

    if (storedToken && !isPublicRoute()) {
      try {
        const decodedToken = jwt_decode(storedToken);
        const res = await Request.Get("/website/token");
        if (!res.type) {
          Cookies.remove("websiteToken", { path: '/' });
          window.location.assign("/");
        } else {
          if (window.location.pathname === "/") {
            window.location.assign("/chat");
          }
        }
        if (decodedToken.exp * 1000 > Date.now()) {
          setIsTokenSet(true);
          return;
        }
      } catch (error) {
        console.error(error);
      }
      Cookies.remove("websiteToken", { path: '/' });
    } else if (!storedToken) {
      try {
        const res = await Request.Get("/public/token");
        const newWebsiteToken = res.websiteToken;
        Cookies.set("websiteToken", newWebsiteToken, { expires: 7, secure: true, path: '/' });
        while (!Cookies.get("websiteToken")) {
          await new Promise((resolve) => setTimeout(resolve, 1000));
        }
        setIsTokenSet(true);
        return newWebsiteToken;
      } catch (error) {
        console.error("Error fetching token:", error);
        throw error;
      }
    } else {
      setIsTokenSet(true);
      return;
    }
  };

  useEffect(() => {
    const checkApiUrlAndHandleToken = async () => {
      if (!Cookies.get("apiUrl")) {
        await getApiUrl();
      }

      if (Cookies.get("apiUrl")) {
        try {
          await handleToken();
        } catch (error) {
          console.error(error);
        }
      }
    };

    checkApiUrlAndHandleToken();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const storedDarkMode = Cookies.get("darkMode");
    if (storedDarkMode !== null) {
      setDarkMode(storedDarkMode === "true");
    }
  }, []);

  const toggleDarkMode = () => {
    setDarkMode((prevMode) => {
      const newMode = !prevMode;
      Cookies.set("darkMode", newMode ? "true" : "false", { expires: 7, path: '/' });
      return newMode;
    });
  };

  if (!isTokenSet) {
    return <Loading darkMode={darkMode} />; // Vous pouvez personnaliser le composant de chargement ici
  }

  return (
    <Router>
      <Wrapper
        darkMode={darkMode}
        toggleDarkMode={toggleDarkMode}
        loading={loading}
      />
      <ToastContainer />
    </Router>
  );
}

export default App;
