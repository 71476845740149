import { Paper } from "@mui/material";
import React, { useEffect, useState } from "react";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import PasswordIcon from "@mui/icons-material/Password";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import useRequest from "../../../../useRequest";
import { toast } from "react-toastify";
import Cookies from 'js-cookie';

import GoogleLoginButton from "../sso/googleLogin";
import MicrosoftLogin from "../sso/microsoftSSO";

import { gapi } from "gapi-script";

import Div100vh, { use100vh } from "react-div-100vh";

import NightsStayOutlinedIcon from "@mui/icons-material/NightsStayOutlined";
import Brightness4OutlinedIcon from "@mui/icons-material/Brightness4Outlined";

const Login = ({ navbarHeight, darkMode, toggleDarkMode }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [formValid, setFormValid] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [googleClientId, setGoogleClientId] = useState("");
  const [microsoftClientId, setMicrosoftClientId] = useState("");
  const [microsoftRedirectUri, setMicrosoftRedirectUri] = useState("");

  const Request = useRequest();

  let hundred = use100vh();

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  useEffect(() => {
    if (email !== "" && password !== "") {
      setFormValid(true);
    } else {
      setFormValid(false);
    }
  }, [email, password]);

  useEffect(() => {
    const fetchData = async () => {
      let google = await Request.Get("/website/google/");
      if (google.clientId)
        setGoogleClientId(google.clientId);
      let microsoft = await Request.Get("/website/microsoft/");
      if (microsoft.clientId)
        setMicrosoftClientId(microsoft.clientId);
      if (microsoft.redirectUri)
        setMicrosoftRedirectUri(microsoft.redirectUri);
    };
    fetchData();
  }, []);

  useEffect(() => {
    if (!googleClientId) return;

    function start() {
      gapi.client.init({
        clientId: googleClientId,
        scope: "",
      })
    };

    gapi.load("client:auth2", start);
  }, [googleClientId]);

  const handleSubmit = async (event) => {
    if (!formValid) return;
    event.preventDefault();
    setDisabled(true);
    const obj = {
      email,
      password,
    };
    let res = await Request.Post("/website/user/login", obj);
    
    if (res.status === 401) {
      toast.error(res.message);
      setDisabled(false);
      return;
    } else if (res.status === 201) {
      const newWebsiteToken = res.token;
      Cookies.set("websiteToken", newWebsiteToken, { expires: 7, secure: true });
      window.location.assign("/welcome");
    } else {
      const newWebsiteToken = res.token;
      Cookies.set("websiteToken", newWebsiteToken, { expires: 7, secure: true });
      window.location.assign("/chat");
    }
  };

  const handleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <>
      <Div100vh
        className="section login"
        style={{
          height: hundred ? `calc(${hundred}px - ${navbarHeight}px)` : "100vh",
        }}
      >
        <div className="lines">
          <div className="line"></div>
          <div className="line"></div>
          <div className="line"></div>
        </div>
        <Paper elevation={2} className="cardLogin">
          <div className="logo">
            {darkMode ? (
              <img
                src="https://ik.imagekit.io/z0tzxea0wgx/Cogitum/aitwork_baseline_darkmode_ZbC5OpB1t?updatedAt=1696434586106"
                alt="logo"
              />
            ) : (
              <img
                src="https://ik.imagekit.io/z0tzxea0wgx/Cogitum/aitwork_baseline_lightmode_wPPQqeb8w?updatedAt=1696434528275"
                alt="logo"
              />
            )}
          </div>
          <form onSubmit={handleSubmit}>
            <div className="form-group">
              <div className="form-head">
                <MailOutlineIcon className="icon" />
                <p htmlFor="email">email</p>
              </div>
              <input
                type="email"
                name="email"
                id="email"
                value={email}
                onChange={handleEmailChange}
                placeholder="Enter your email address"
                required
              />
            </div>
            <div className="form-group">
              <div className="pass">
                <div className="form-head">
                  <PasswordIcon className="icon" />
                  <p htmlFor="password">password</p>
                </div>
                {showPassword ? (
                  <VisibilityOffIcon
                    className="icon clickable"
                    onClick={handleShowPassword}
                  />
                ) : (
                  <VisibilityIcon
                    className="icon clickable"
                    onClick={handleShowPassword}
                  />
                )}
              </div>
              <input
                type={showPassword ? "text" : "password"}
                name="password"
                id="password"
                placeholder="Enter your password"
                value={password}
                onChange={handlePasswordChange}
                required
              />
            </div>
            <button type="submit" disabled={!formValid || disabled}>
              Login
            </button>
            <a className="login-link" href="/password-recovery">I forgot my password</a>
          </form>
          <div className="line-separator">
            <div className="line"></div>
          </div>
          {googleClientId !== "" && (
            <GoogleLoginButton Request={Request} clientId={googleClientId}/>
          )}
          { (microsoftClientId !== "" && microsoftRedirectUri !== "") && (
            <MicrosoftLogin Request={Request} clientId={microsoftClientId} redirectUri={microsoftRedirectUri}/>
          )}
          <a className="login-link" href="/register">Sign up</a>
        </Paper>
        <div className="footer">
          <p>
            {" "}
            © All rights reserved -{" "}
            <a href="https://www.iadopt.fr" target="_blanc" rel="noreferrer">
              iadopt.
            </a>{" "}
            - {new Date().getFullYear()}
          </p>
        </div>
      </Div100vh>

      <div className="toggle-darkmode" onClick={toggleDarkMode}>
        {darkMode ? <Brightness4OutlinedIcon /> : <NightsStayOutlinedIcon />}
      </div>
    </>
  );
};

export default Login;
