import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const ConfirmationModal = ({ open, title, text, onConfirm, onCancel }) => (
  <Modal
    open={open}
    onClose={onCancel}
    aria-labelledby="modal-modal-title"
    aria-describedby="modal-modal-description"
  >
    <Box sx={style}>
      <h2>{title}</h2>
      <p>{text}</p>
      <button onClick={onConfirm}>Oui</button>
      <button onClick={onCancel}>Non</button>
    </Box>
  </Modal>
);

export default ConfirmationModal;