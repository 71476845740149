const Checklist = ({ param, checklistActiveButton, checklistButtonClick, selectedItems, toggleItem, toggleAllItems }) => (
    <div className="checklist-container">
      {param.type === "plurial-checklist" ? (
        param.value.map((item, idx) => (
          <div key={idx}>
            <div className="header">
              <h4>• {item.name}</h4>
              <h4 className="toggle-all-button" onClick={() => toggleAllItems(item.fields, item)}>
                {item.fields.every((field) => selectedItems.includes(field)) ? "Tout décocher" : "Tout cocher"}
              </h4>
            </div>
            <div className="buttons">
              {item.fields.map((field, fieldIdx) => (
                <div
                  key={fieldIdx}
                  className={`btn ${selectedItems.includes(field) ? "active" : ""}`}
                  onClick={() => toggleItem(field, item)}
                >
                  {field.exigence}
                </div>
              ))}
            </div>
          </div>
        ))
      ) : (
        <div className="buttons">
          {param.value.map((item, idx) => (
            <div
              className={`btn ${idx === checklistActiveButton ? "active" : ""}`}
              key={idx}
              onClick={() => checklistButtonClick(item, idx)}
            >
              {item}
            </div>
          ))}
        </div>
      )}
    </div>
  );

export default Checklist;