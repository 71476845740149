import React, { useState, useEffect, useRef, useCallback } from "react";
import { useNavigate } from "react-router-dom";

import useRequest from "../../../useRequest.js";

import { Switch } from "@mui/material";

import NightsStayOutlinedIcon from "@mui/icons-material/NightsStayOutlined";
import Brightness4OutlinedIcon from "@mui/icons-material/Brightness4Outlined";

import ChatSidebar from "../components/sidebar/chat/chat.jsx";
import SettingsSidebar from "../components/sidebar/settings/settings.jsx";

import ChatContainer from "../components/chat/chat.jsx";
import SettingsContainer from "../components/settings/settings.jsx";
import Cookies from "js-cookie";
import PDFViewer from "./PDFViewer.jsx";

import Div100vh from "react-div-100vh";
import { toast } from "react-toastify";

import Zendesk from "react-zendesk";
const ZENDESK_KEY = "f5b5d4bd-e2f3-402c-a9f5-187d06920c07";

const Cogichat = ({ darkMode, toggleDarkMode }) => {
  const [messages, setMessages] = useState([]);
  const [defaultMessages, setDefaultMessages] = useState([]);
  const [template, setTemplate] = useState("");
  const [defaultTemplate, setDefaultTemplate] = useState("");
  const [files, setFiles] = useState([]);
  const [chats, setChats] = useState([]);
  const [selectedChat, setSelectedChat] = useState({
    name: null,
    id: null,
    favorite: false,
  });
  const [storedToken, setStoredToken] = useState("");
  const [progress, setProgress] = useState(0);
  const [decryptedToken, setDecryptedToken] = useState("");
  const [settings, setSettings] = useState("");
  const [close, setClose] = useState(window.innerWidth <= 767);
  const [user, setUser] = useState(null);
  const [agents, setAgents] = useState([]);
  const [selectedAgent, setSelectedAgent] = useState({
    name: null,
    id: null,
  });
  const [isNarrowScreen, setIsNarrowScreen] = useState(
    window.innerWidth < 1100
  );
  const [company, setCompany] = useState(null);

  const [showPdfViewer, setShowPdfViewer] = useState(false);
  const [pdfFile, setPdfFile] = useState("");
  const [pdfPage, setPdfPage] = useState(1);
  const [highlightText, setHighlightText] = useState("");

  const [generationTaskId, setGenerationTaskId] = useState(null);
  const socket = useRef(null);

  const setting = {
    color: {
      theme: "#000"
    },
    launcher: {
      chatLabel: {
        "fr-FR": "Besoin d'aide"
      }
    },
    contactForm: {
      fields: [
        { id: "description", prefill: { "*": "My pre-filled description" } }
      ]
    }
  };
  
  useEffect(() => {
    function handleResize() {
      setIsNarrowScreen(window.innerWidth < 1100);
    }

    // Ajoute l'écouteur d'événement au montage du composant
    window.addEventListener("resize", handleResize);

    // Supprime l'écouteur d'événement au démontage du composant
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const sidebarClass = `sidebar ${isNarrowScreen ? "absolute" : ""}`;

  const handleStopGeneration = useCallback(() => {
    if (generationTaskId && socket.current) {
      socket.current.emit("stopGeneration", {
        taskId: generationTaskId,
        chatId: selectedChatRef.current.id,
        chatName: selectedChatRef.current.name,
      });
    }
    // eslint-disable-next-line
  }, [generationTaskId]);

  const navigate = useNavigate();

  const navigateToDashboard = () => {
    navigate("/dashboard");
  };

  const Request = useRequest();

  const selectedChatRef = useRef(selectedChat);
  const selectedAgentRef = useRef(selectedAgent);
  const progressRef = useRef(progress);

  // METHODS

  const updateChatDb = async (chat) => {
    await Request.Put("/protected/chats/update", chat);

    if (chat.name) {
      setSelectedChat({
        ...selectedChat,
        name: chat.name,
      });
    }
  };

  const initTemplate = () => {
    setTemplate(-1);
  }

  // USE_EFFECTS

  useEffect(() => {
    const fetchData = async () => {
      const res = await Request.Get("/protected/agents");
      setAgents(res.folders);
    };
    fetchData();
  }, []);

  useEffect(() => {
    if (selectedChat.name && selectedChat.id) {
      updateChatDb({
        id: selectedChat.id,
        files: files,
      });
    }
    // eslint-disable-next-line
  }, [files]);

  useEffect(() => {
    if (template === -1) {
      setTemplate(defaultTemplate);
    }
  //eslint-disable-next-line
  }, [template]);

  useEffect(() => {
    progressRef.current = progress;
  }, [progress]);

  useEffect(() => {
    // if (!storedToken) setStoredToken(localStorage.getItem("websiteToken"));
    if (!storedToken) setStoredToken(Cookies.get("websiteToken"));
  }, [storedToken]);

  useEffect(() => {
    if (storedToken) {
      setDecryptedToken(storedToken);
    }
  }, [storedToken]);

  useEffect(() => {
    const fetchData = async () => {
      const res = await Request.Get("/protected/user");
      setUser(res);
    };
    fetchData();
    // eslint-disable-next-line
  }, [decryptedToken]);

  useEffect(() => {
    setShowPdfViewer(false);
    selectedChatRef.current = selectedChat;
    setProgress(0);
    //eslint-disable-next-line
  }, [selectedChat]);

  useEffect(() => {
    selectedAgentRef.current = selectedAgent;
  }, [selectedAgent]);

  useEffect(() => {
    if (showPdfViewer) setShowPdfViewer(false);
    if (settings === "") {
      setTimeout(() => {
        setSelectedChat({
          name: selectedChatRef.current.name,
          id: selectedChatRef.current.id,
          favorite: selectedChatRef.current.favorite,
        });
      }, 400);
    }
    // eslint-disable-next-line
  }, [settings]);

  useEffect(() => {
    if (showPdfViewer) setClose(true);
  }, [showPdfViewer]);
  
  useEffect(() => {
    const fetchData = async () => {
      try {
        if (!user) {
          return;
        }
        
        let companyRes = await Request.Get("protected/company");
        setCompany(companyRes);
        
        if (companyRes.defaultProject) {
          setDefaultTemplate(companyRes.defaultProject);
          setTemplate(companyRes.defaultProject);
          setDefaultMessages([
            {
              role: "function",
              content: null,
              template: companyRes.defaultProject,
            },
          ]);
          setMessages([
            {
              role: "function",
              content: null,
              template: companyRes.defaultProject,
            },
          ]);
        }
      } catch (error) {
        console.error("Failed to fetch data:", error);
      }
    };
        
    if (!company) {
       fetchData();
    }
    // eslint-disable-next-line
  }, [user, company]);

  // Handler

  const handleLogout = () => {
    Cookies.remove("websiteToken");
    window.location.href = "/";
  };

  const handleSelectChat = useCallback((event) => {
    if (!event.favorite) setSelectedChat(event);
    else setSelectedChat({ name: null, id: null, favorite: false });

    const fetchChatData = async (chat) => {
      const response = await Request.Get(`/protected/chats/one/${chat.id}`);
      const chatData = response.chat;
      setMessages(chatData[0].chat.messages);
      setFiles(chatData[0].files);
    };

    fetchChatData(event);
    // eslint-disable-next-line
  }, []);

  const handleSettings = () => {
    if (!decryptedToken) return;
    if (settings !== "") setSettings("");
    else setSettings("settings");
  };

  return (
    <Div100vh className="dashboard-container">
      <Div100vh className="cogichat">
        <Div100vh
          className={`sidebar ${close ? "close" : "open"} ${
            isNarrowScreen ? "absolute" : ""
          }`}
        >
          <div className="arrow" onClick={() => setClose(!close)}>
            {close ? (
              <svg
                stroke="currentColor"
                fill="none"
                strokeWidth="2"
                viewBox="0 0 24 24"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="w-4 h-4 double-right-arrow"
                height="1em"
                width="1em"
                xmlns="http://www.w3.org/2000/svg"
              >
                <polyline points="13 4 20 12 13 20"></polyline>
                <polyline points="5 4 12 12 5 20"></polyline>
              </svg>
            ) : (
              <svg
                stroke="currentColor"
                fill="none"
                strokeWidth="2"
                viewBox="0 0 24 24"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="w-4 h-4 double-left-arrow"
                height="1em"
                width="1em"
                xmlns="http://www.w3.org/2000/svg"
              >
                <polyline points="11 4 4 12 11 20"></polyline>
                <polyline points="19 4 12 12 19 20"></polyline>
              </svg>
            )}
          </div>
          <div className="sidebar-content">
            <div
              className={`logo ${settings ? "active" : "hidden"}`}
              onClick={() => {
                settings !== "" && setSettings("");
              }}
            >
              <div className="back">
                <svg
                  className={settings ? "active" : "hidden"}
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <line x1="19" y1="12" x2="5" y2="12"></line>
                  <polyline points="12 19 5 12 12 5"></polyline>
                </svg>
              </div>
              <div className="box">
                {darkMode ? (
                  <img
                    src="https://ik.imagekit.io/z0tzxea0wgx/Cogitum/aitwork_baseline_darkmode_ZbC5OpB1t?updatedAt=1696434586106"
                    alt="logo"
                  />
                ) : (
                  <img
                    src="https://ik.imagekit.io/z0tzxea0wgx/Cogitum/aitwork_baseline_lightmode_wPPQqeb8w?updatedAt=1696434528275"
                    alt="logo"
                  />
                )}
              </div>
              <div className="back"></div>
            </div>

            {settings === "" ? (
              <ChatSidebar
                Request={Request}
                chats={chats}
                setChats={setChats}
                setMessages={setMessages}
                setTemplate={setTemplate}
                setFiles={setFiles}
                setSelectedChat={setSelectedChat}
                selectedChat={selectedChat}
                handleSelectChat={handleSelectChat}
                updateChatDb={updateChatDb}
                plugin="cogichat"
                agents={agents}
                setSelectedAgent={setSelectedAgent}
                user={user}
                setUser={setUser}
                defaultMessages={defaultMessages}
                defaultTemplate={defaultTemplate}
                initTemplate={initTemplate}
              />
            ) : (
              <SettingsSidebar
                settings={settings}
                setSettings={setSettings}
                user={user}
                cogiproducts={"cogichat"}
              />
            )}

            <div className="email">
              <svg
                stroke="currentColor"
                fill="none"
                strokeWidth="2"
                viewBox="0 0 24 24"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="w-4 h-4"
                height="1em"
                width="1em"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
                <circle cx="12" cy="7" r="4"></circle>
              </svg>
              {user && <p>{user.email}</p>}
            </div>
            <div className="logout">
              <div className="btn" onClick={() => handleLogout()}>
                <div className="icon">
                  {" "}
                  <svg
                    stroke="currentColor"
                    fill="none"
                    strokeWidth="2"
                    viewBox="0 0 24 24"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="w-4 h-4"
                    height="1em"
                    width="1em"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4"></path>
                    <polyline points="16 17 21 12 16 7"></polyline>
                    <line x1="21" y1="12" x2="9" y2="12"></line>
                  </svg>
                </div>
                <div className="text">Déconnexion</div>
              </div>
              <div
                className={`btn ${settings ? "active" : ""}`}
                onClick={() => handleSettings()}
              >
                <svg
                  stroke="currentColor"
                  fill="none"
                  strokeWidth="2"
                  viewBox="0 0 24 24"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  className="w-4 h-4"
                  height="1em"
                  width="1em"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle cx="12" cy="12" r="3"></circle>
                  <path d="M19.4 15a1.65 1.65 0 0 0 .33 1.82l.06.06a2 2 0 0 1 0 2.83 2 2 0 0 1-2.83 0l-.06-.06a1.65 1.65 0 0 0-1.82-.33 1.65 1.65 0 0 0-1 1.51V21a2 2 0 0 1-2 2 2 2 0 0 1-2-2v-.09A1.65 1.65 0 0 0 9 19.4a1.65 1.65 0 0 0-1.82.33l-.06.06a2 2 0 0 1-2.83 0 2 2 0 0 1 0-2.83l.06-.06a1.65 1.65 0 0 0 .33-1.82 1.65 1.65 0 0 0-1.51-1H3a2 2 0 0 1-2-2 2 2 0 0 1 2-2h.09A1.65 1.65 0 0 0 4.6 9a1.65 1.65 0 0 0-.33-1.82l-.06-.06a2 2 0 0 1 0-2.83 2 2 0 0 1 2.83 0l.06.06a1.65 1.65 0 0 0 1.82.33H9a1.65 1.65 0 0 0 1-1.51V3a2 2 0 0 1 2-2 2 2 0 0 1 2 2v.09a1.65 1.65 0 0 0 1 1.51 1.65 1.65 0 0 0 1.82-.33l.06-.06a2 2 0 0 1 2.83 0 2 2 0 0 1 0 2.83l-.06.06a1.65 1.65 0 0 0-.33 1.82V9a1.65 1.65 0 0 0 1.51 1H21a2 2 0 0 1 2 2 2 2 0 0 1-2 2h-.09a1.65 1.65 0 0 0-1.51 1z"></path>
                </svg>
              </div>
              <div className="toggle-container">
                <Switch
                  className="toggle"
                  checked={darkMode}
                  onChange={toggleDarkMode}
                  inputProps={{ "aria-label": "Toggle dark mode" }}
                />
                {darkMode ? (
                  <NightsStayOutlinedIcon className="iconColor dark-mode" />
                ) : (
                  <Brightness4OutlinedIcon className="iconColor light-mode" />
                )}
              </div>
            </div>
          </div>
        </Div100vh>
        <Div100vh className="big-container">
          {settings === "" ? (
            <ChatContainer
              Request={Request}
              selectedChat={selectedChat}
              selectedChatRef={selectedChatRef}
              updateChatDb={updateChatDb}
              decryptedToken={decryptedToken}
              setChats={setChats}
              setSelectedChat={setSelectedChat}
              darkMode={darkMode}
              messages={messages}
              setMessages={setMessages}
              template={template}
              setTemplate={setTemplate}
              user={user}
              setSettings={setSettings}
              plugin="cogichat"
              close={close}
              setClose={setClose}
              handleSettings={handleSettings}
              navigateToDashboard={navigateToDashboard}
              progress={progressRef.current}
              setProgress={setProgress}
              socket={socket}
              setGenerationTaskId={setGenerationTaskId}
              handleStopGeneration={handleStopGeneration}
              selectedAgentRef={selectedAgentRef}
              files={files}
              setFiles={setFiles}
              setShowPdfViewer={setShowPdfViewer}
              setPdfFile={setPdfFile}
              setPdfPage={setPdfPage}
              setHighlightText={setHighlightText}
              defaultMessages={defaultMessages}
              defaultTemplate={defaultTemplate}
            />
          ) : (
            <SettingsContainer
              settings={settings}
              handleSettings={handleSettings}
              user={user}
              setUser={setUser}
              socket={socket}
              plugin="cogichat"
            />
          )}
          {showPdfViewer && (
            <Div100vh className="pdf-viewer-container">
              <PDFViewer
                fileUrl={pdfFile}
                pageNumber={pdfPage}
                highlightText={highlightText}
                setShowPdfViewer={setShowPdfViewer}
                darkMode={darkMode}
              />
            </Div100vh>
          )}
        </Div100vh>
      </Div100vh>
      {/* <Zendesk defer zendeskKey={ZENDESK_KEY} {...setting}/>; */}
    </Div100vh>
  );
};

export default Cogichat;
