import React from 'react';
import ReactDOM from 'react-dom/client';
import './style.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root'));

console.log(`
█████╗ ██╗████████╗██╗    ██╗ ██████╗ ██████╗ ██╗  ██╗
██╔══██╗██║╚══██╔══╝██║    ██║██╔═══██╗██╔══██╗██║ ██╔╝
███████║██║   ██║   ██║ █╗ ██║██║   ██║██████╔╝█████╔╝
██╔══██║██║   ██║   ██║███╗██║██║   ██║██╔══██╗██╔═██╗
██║  ██║██║   ██║   ╚███╔███╔╝╚██████╔╝██║  ██║██║  ██╗
╚═╝  ╚═╝╚═╝   ╚═╝    ╚══╝╚══╝  ╚═════╝ ╚═╝  ╚═╝╚═╝  ╚═╝

`)



root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

reportWebVitals();
